package games.bigoud.eucoalitionplanningfront.components.sections.setupSchedule

import androidx.compose.runtime.*
import androidx.compose.runtime.setValue
import games.bigoud.eucoalitionplanningfront.components.widgets.PlanningTab
import games.bigoud.eucoalitionplanningfront.components.widgets.dateSelector
import games.bigoud.eucoalitionplanningfront.config.planningService
import games.bigoud.eucoalitionplanningfront.models.DiscordUser
import games.bigoud.eucoalitionplanningfront.models.SetupScheduleWeek
import games.bigoud.eucoalitionplanningfront.models.TaskAvailability
import games.bigoud.eucoalitionplanningfront.models.TaskStatus
import games.bigoud.eucoalitionplanningfront.modelsNetwork.UpdateScheduleRequest
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.todayIn
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

@Composable
fun AuthenticatedSetupScheduleSection(user: DiscordUser) {

    val scope = rememberCoroutineScope()

    var planning by remember { mutableStateOf(SetupScheduleWeek.dummy()) }

    var date = Clock.System.todayIn(TimeZone.of("Europe/Paris")).plus(1, DateTimeUnit.WEEK)
    var numberOfDayInWeek = date.dayOfWeek.ordinal
    var mondayDate by remember { mutableStateOf(date.minus(numberOfDayInWeek, DateTimeUnit.DAY)) }

    scope.launch {
        val token = window.localStorage.getItem("token")
        planning = planningService.getSetupScheduleTest(
            token ?: "",
            mondayDate.year.toString(),
            mondayDate.monthNumber.toString(),
            mondayDate.dayOfMonth.toString(),
        )
    }

    dateSelector(mondayDate) { newDate ->
        mondayDate = newDate
    }

    PlanningTab(user, mondayDate) { date, taskType ->
        var selected1 by remember(planning.weekNumber, planning.year) { mutableStateOf(String()) }
        var selected2 by remember(planning.weekNumber, planning.year) { mutableStateOf(String()) }

        if (selected1.isBlank()) {
            selected1 = planning.tasks.firstOrNull { task ->
                task.day == date && task.taskType == taskType && task.taskStatus == TaskStatus.CONFIRMED && task.taskAvailability != TaskAvailability.UNAVAILABLE
            }?.userId ?: ""
        }
        if (selected2.isBlank()) {
            selected2 = planning.tasks.firstOrNull { task ->
                task.day == date && task.taskType == taskType && task.taskStatus == TaskStatus.BACKUP && task.taskAvailability != TaskAvailability.UNAVAILABLE
            }?.userId ?: ""
        }


        Div {
            Select(
                {
                    attr("value", selected1)
                    onChange {
                        if (selected1.isNotEmpty()) {
                            planning = planning.updateTaskStatus(date, selected1, taskType, TaskStatus.PENDING)
                        }
                        selected1 = it.value ?: ""
                        if (selected1.isNotEmpty()) {
                            planning = planning.updateTaskStatus(date, selected1, taskType, TaskStatus.CONFIRMED)
                        }
                    }
                }
            ) {
                Option(
                    value = ""
                ) {
                    Text("")
                }
                planning.tasks.filter { task ->
                    task.day == date
                }.filter { task ->
                    task.taskType == taskType
                }.filter { task ->
                    task.userId != selected2
                }.forEach { task ->
                    val name = planning.usernames[task.userId] + when (task.taskAvailability) {
                        TaskAvailability.WISH -> " ❤\uFE0F"
                        TaskAvailability.AVAILABLE -> " ✔\uFE0F"
                        TaskAvailability.MAYBE -> " \uD83E\uDD37"
                        TaskAvailability.UNAVAILABLE -> " ❌"
                    }
                    Option(
                        value = task.userId,
                        attrs = {
                            if (task.userId == selected1) {
                                selected()
                            }
                        }
                    ) {
                        Text(name)
                    }
                }
            }
            Select(
                {
                    attr("value", selected2)
                    onChange {
                        if (selected2.isNotEmpty()) {
                            planning = planning.updateTaskStatus(date, selected2, taskType, TaskStatus.PENDING)
                        }
                        selected2 = it.value ?: ""
                        if (selected2.isNotEmpty()) {
                            planning = planning.updateTaskStatus(date, selected2, taskType, TaskStatus.BACKUP)
                        }
                    }
                }
            ) {
                Option(
                    value = ""
                ) {
                    Text("")
                }
                planning.tasks.filter { task ->
                    task.day == date
                }.filter { task ->
                    task.taskType == taskType
                }.filter { task ->
                    task.userId != selected1
                }.forEach { task ->
                    val name = planning.usernames[task.userId] + when (task.taskAvailability) {
                        TaskAvailability.WISH -> " ❤\uFE0F"
                        TaskAvailability.AVAILABLE -> " ✔\uFE0F"
                        TaskAvailability.MAYBE -> " \uD83E\uDD37"
                        TaskAvailability.UNAVAILABLE -> " ❌"
                    }
                    Option(
                        value = task.userId,
                        attrs = {
                            if (task.userId == selected2) {
                                selected()
                            }
                            println()
                        }
                    ) {
                        Text(name)
                    }
                }
            }
        }
    }
    Button(
        {
            onClick{
                scope.launch {
                    val token = window.localStorage.getItem("token")
                    val response = planningService.updateSetupSchedule(UpdateScheduleRequest(token?: "", planning))
                    if (response) {

                    }
                }
            }
        }
    ) {
        Text("Click me")
    }

}
