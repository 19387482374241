package games.bigoud.eucoalitionplanningfront.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WordBreak
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderLeft
import com.varabyte.kobweb.compose.ui.modifiers.borderRight
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.wordBreak
import com.varabyte.kobweb.silk.components.text.SpanText
import games.bigoud.eucoalitionplanningfront.models.DiscordUser
import games.bigoud.eucoalitionplanningfront.models.TaskType
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.plus
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb

@Composable
fun PlanningTab(
    user: DiscordUser,
    //data: Week,
    mondayDate: LocalDate,
    cellContent: @Composable (LocalDate, TaskType) -> Unit,
) {
    Column(
        modifier = Modifier
            .fillMaxHeight(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        JobRow()
        ConfDistribRow(user, mondayDate, cellContent)
        GroupsRow(user, mondayDate, cellContent)
        BriefRow(user, mondayDate, cellContent)
        LeadRow(user, mondayDate, cellContent)
    }
}

@Composable
fun JobRow() {
    Row(
        modifier = Modifier
            .borderLeft(1.px, LineStyle.Solid, Color.black)
            .borderRight(1.px, LineStyle.Solid, Color.black)
            .borderTop(1.px, LineStyle.Solid, Color.black)
            .backgroundColor(rgb(217, 217, 217)),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Box(
            modifier = Modifier
                .height(50.px)
                .width(250.px)
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "JOB",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "ADMIN",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Lundi",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px).fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Mardi",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px).fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Mercredi",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px).fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Jeudi",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px).fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Vendredi",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px).fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Samedi",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Dimanche",
                modifier = Modifier
                    .color(Color.black)
            )
        }
    }
}

@Composable
fun ConfDistribRow(
    user: DiscordUser,
    //data: List<Task>,
    mondayDate: LocalDate,
    cellContent: @Composable (LocalDate, TaskType) -> Unit,
) {
    Row(
        modifier = Modifier
            .borderLeft(1.px, LineStyle.Solid, Color.black)
            .borderTop(1.px, LineStyle.Solid, Color.black)
            .borderRight(1.px, LineStyle.Solid, Color.black)
            .backgroundColor(rgb(217, 233, 211)),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Box(
            modifier = Modifier
                .height(50.px)
                .width(250.px)
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "5pm : CONFIRMATION & DISTRIBUTION",
                modifier = Modifier
                    .wordBreak(WordBreak.Normal)
                    .textAlign(TextAlign.Center)
                    .padding(leftRight = 10.px)
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Duo voc",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate, TaskType.CONF_DISTRIB)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(1, DateTimeUnit.DAY), TaskType.CONF_DISTRIB)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(2, DateTimeUnit.DAY), TaskType.CONF_DISTRIB)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(3, DateTimeUnit.DAY), TaskType.CONF_DISTRIB)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(4, DateTimeUnit.DAY), TaskType.CONF_DISTRIB)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(5, DateTimeUnit.DAY), TaskType.CONF_DISTRIB)
        }
        Box(
            modifier = Modifier
                .width(100.px),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(6, DateTimeUnit.DAY), TaskType.CONF_DISTRIB)
        }
    }
}

@Composable
fun GroupsRow(
    user: DiscordUser,
    //data: List<Task>,
    mondayDate: LocalDate,
    cellContent: @Composable (LocalDate, TaskType) -> Unit,
) {
    Row(
        modifier = Modifier
            .borderLeft(1.px, LineStyle.Solid, Color.black)
            .borderTop(1.px, LineStyle.Solid, Color.black)
            .borderRight(1.px, LineStyle.Solid, Color.black)
            .backgroundColor(rgb(215, 236, 255)),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Box(
            modifier = Modifier
                .height(50.px)
                .width(250.px)
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "8pm : GROUPS",
                modifier = Modifier
                    .wordBreak(WordBreak.Normal)
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .height(50.px)
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            Column(
                modifier = Modifier,
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
            ) {
                Box(
                    modifier = Modifier
                        .width(100.px),
                    contentAlignment = Alignment.Center,
                ) {
                    SpanText(
                        text = "Main",
                        modifier = Modifier
                            .wordBreak(WordBreak.Normal)
                            .color(Color.black)
                    )
                }
                Box(
                    modifier = Modifier
                        .width(100.px),
                    contentAlignment = Alignment.Center,
                ) {
                    SpanText(
                        text = "Backup",
                        modifier = Modifier
                            .wordBreak(WordBreak.Normal)
                            .color(Color.black)
                    )
                }
            }
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate, TaskType.GROUPS)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(1, DateTimeUnit.DAY), TaskType.GROUPS)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(2, DateTimeUnit.DAY), TaskType.GROUPS)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(3, DateTimeUnit.DAY), TaskType.GROUPS)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(4, DateTimeUnit.DAY), TaskType.GROUPS)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(5, DateTimeUnit.DAY), TaskType.GROUPS)
        }
        Box(
            modifier = Modifier
                .width(100.px),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(6, DateTimeUnit.DAY), TaskType.GROUPS)
        }
    }
}

@Composable
fun BriefRow(
    user: DiscordUser,
    //data: List<Task>,
    mondayDate: LocalDate,
    cellContent: @Composable (LocalDate, TaskType) -> Unit,
) {
    Row(
        modifier = Modifier
            .borderLeft(1.px, LineStyle.Solid, Color.black)
            .borderTop(1.px, LineStyle.Solid, Color.black)
            .borderRight(1.px, LineStyle.Solid, Color.black)
            .backgroundColor(rgb(252, 229, 205)),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Box(
            modifier = Modifier
                .height(50.px)
                .width(250.px)
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "8:18pm : BRIEF",
                modifier = Modifier
                    .wordBreak(WordBreak.Normal)
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Main",
                modifier = Modifier
                    .color(Color.black)
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate, TaskType.BRIEF)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(1, DateTimeUnit.DAY), TaskType.BRIEF)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(2, DateTimeUnit.DAY), TaskType.BRIEF)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(3, DateTimeUnit.DAY), TaskType.BRIEF)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(4, DateTimeUnit.DAY), TaskType.BRIEF)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(5, DateTimeUnit.DAY), TaskType.BRIEF)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(6, DateTimeUnit.DAY), TaskType.BRIEF)
        }
    }
}

@Composable
fun LeadRow(
    user: DiscordUser,
    //data: List<Task>,
    mondayDate: LocalDate,
    cellContent: @Composable (LocalDate, TaskType) -> Unit,
) {
    Row(
        modifier = Modifier
            .borderLeft(1.px, LineStyle.Solid, Color.black)
            .borderTop(1.px, LineStyle.Solid, Color.black)
            .borderRight(1.px, LineStyle.Solid, Color.black)
            .borderBottom(1.px, LineStyle.Solid, Color.black)
            .backgroundColor(rgb(244, 203, 204)),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Box(
            modifier = Modifier
                .height(50.px)
                .width(250.px)
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "8:20pm : LEAD",
                modifier = Modifier
                    .wordBreak(WordBreak.Normal)
                    .color(Color.black),
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Solid, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            SpanText(
                text = "Main",
                modifier = Modifier
                    .color(Color.black),
            )
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate, TaskType.LEAD)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(1, DateTimeUnit.DAY), TaskType.LEAD)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(2, DateTimeUnit.DAY), TaskType.LEAD)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(3, DateTimeUnit.DAY), TaskType.LEAD)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(4, DateTimeUnit.DAY), TaskType.LEAD)
        }
        Box(
            modifier = Modifier
                .width(100.px)
                .fillMaxHeight()
                .borderRight(1.px, LineStyle.Dotted, Color.black),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(5, DateTimeUnit.DAY), TaskType.LEAD)
        }
        Box(
            modifier = Modifier
                .width(100.px),
            contentAlignment = Alignment.Center,
        ) {
            cellContent(mondayDate.plus(6, DateTimeUnit.DAY), TaskType.LEAD)
        }
    }
}
