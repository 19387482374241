package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsFr : Strings {
  override val connection: String = "Connexion avec Discord"

  override val template_starting_point: String? = null

  override val about_part1: String? = null

  override val about_part2: String? = null

  override val about: String? = null

  override val cta: String? = null
}
