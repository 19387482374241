package games.bigoud.eucoalitionplanningfront.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.text.SpanText
import games.bigoud.eucoalitionplanningfront.utils.localized
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button


@Composable
fun dateSelector(date: LocalDate, onDateUpdated: (LocalDate) -> Unit) {
    Row(){
        Button(attrs = {
            onClick {
                onDateUpdated(date.minus(1, DateTimeUnit.WEEK))
            }
        }){
            FaArrowLeft()
        }
        SpanText(
            text = "${date.localized()} - ${date.plus(6, DateTimeUnit.DAY).localized()}",
            modifier = Modifier
                .margin(leftRight = 10.px)
            )
        Button(attrs = {
            onClick {
                onDateUpdated(date.plus(1, DateTimeUnit.WEEK))
            }
        }) {
            FaArrowRight()
        }
    }
}