package games.bigoud.eucoalitionplanningfront.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import games.bigoud.eucoalitionplanningfront.config.client
import games.bigoud.eucoalitionplanningfront.models.DiscordUser
import games.bigoud.eucoalitionplanningfront.models.OAuthResponse
import games.bigoud.eucoalitionplanningfront.utils.Constants.BACKEND_URL
import io.ktor.client.request.forms.submitForm
import io.ktor.client.statement.bodyAsText
import io.ktor.http.parameters
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json

@Page("oauth-redirect")
@Composable
fun OAuthRedirect() {
    val scope = rememberCoroutineScope()
    val ctx = rememberPageContext()

    scope.launch {

        var oauthResponse = Json.decodeFromString<OAuthResponse>(
            client.submitForm(
                url = "${BACKEND_URL}oauth",
                formParameters = parameters {
                    append("code", window.location.search.substringAfter("=").substringBefore("&"))
                }
            ).bodyAsText()
        )

        window.localStorage.setItem("token", oauthResponse.token)
        var userResponse = Json.decodeFromString<DiscordUser>(
            client.submitForm(
                url = "$BACKEND_URL/user",
                formParameters = parameters {
                    append("token", oauthResponse.token)
                }
            ).bodyAsText()
        )

        val path = "/${window.location.search.substringAfter("state=", "").substringBefore("&")}"



        ctx.router.navigateTo(path)
    }
}
