package games.bigoud.eucoalitionplanningfront.models

import kotlinx.serialization.Serializable

@Serializable
enum class TaskType {
    CONF_DISTRIB,
    GROUPS,
    BRIEF,
    LEAD,
}
