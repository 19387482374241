package games.bigoud.eucoalitionplanningfront.services

import de.jensklingenberg.ktorfit.http.Body
import de.jensklingenberg.ktorfit.http.Field
import de.jensklingenberg.ktorfit.http.FormUrlEncoded
import de.jensklingenberg.ktorfit.http.Header
import de.jensklingenberg.ktorfit.http.POST
import games.bigoud.eucoalitionplanningfront.models.Planning
import games.bigoud.eucoalitionplanningfront.models.SetupScheduleWeek
import games.bigoud.eucoalitionplanningfront.models.Week
import games.bigoud.eucoalitionplanningfront.modelsNetwork.UpdateScheduleRequest
import games.bigoud.eucoalitionplanningfront.modelsNetwork.UpdateWeekRequest

interface PlanningApi {
    @POST("planning")
    @FormUrlEncoded
    suspend fun getPlanning(
        @Field("token") token: String,
        @Field("year") year: String,
        @Field("month") month: String,
        @Field("day") day: String,
    ): Planning

    @POST("planning_user")
    @FormUrlEncoded
    suspend fun getAvailabilitySchedule(
        @Field("token") token: String,
        @Field("year") week: String,
        @Field("month") year: String,
        @Field("day") day: String,
    ): Week

    @POST("update_planning_user")
    suspend fun updateAvailabilitySchedule(
        @Body weekPlanning: UpdateWeekRequest,
        @Header("Content-Type") contentType: String = "application/json",
    ): Boolean

    @POST("setup_planning")
    @FormUrlEncoded
    suspend fun getSetupScheduleTest(
        @Field("token") token: String,
        @Field("year") week: String,
        @Field("month") year: String,
        @Field("day") day: String,
    ): SetupScheduleWeek

    @POST("update_setup_planning")
    suspend fun updateSetupSchedule(
        @Body planning: UpdateScheduleRequest,
        @Header("Content-Type") contentType: String = "application/json",
    ): Boolean
}
