package games.bigoud.eucoalitionplanningfront.config

import de.jensklingenberg.ktorfit.converter.CallConverterFactory
import de.jensklingenberg.ktorfit.converter.FlowConverterFactory
import de.jensklingenberg.ktorfit.ktorfit
import games.bigoud.eucoalitionplanningfront.services.AuthApi
import games.bigoud.eucoalitionplanningfront.services.PlanningApi
import games.bigoud.eucoalitionplanningfront.services.createAuthApi
import games.bigoud.eucoalitionplanningfront.services.createPlanningApi
import games.bigoud.eucoalitionplanningfront.utils.Constants.BACKEND_URL
import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.logging.DEFAULT
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json

val client = HttpClient(Js) {
    install(Logging) {
        level = LogLevel.ALL // Capture toutes les requêtes et réponses
        logger = Logger.DEFAULT // Utiliser le logger par défaut ou personnaliser
    }
    install(ContentNegotiation) {
        json(Json {
            isLenient = true
            ignoreUnknownKeys = true
        })
    }
}

val ktorfit = ktorfit {
    baseUrl(BACKEND_URL)
    httpClient(client)
    converterFactories(
        FlowConverterFactory(),
        CallConverterFactory(),
    )
}

val authService = ktorfit.createAuthApi()
val planningService = ktorfit.createPlanningApi()
