package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("en" to StringsEn, "fr" to StringsFr)

  public val connection: String
    get() = locales[getCurrentLanguageCode()]?.connection ?: baseLocale.connection

  public val template_starting_point: String
    get() = locales[getCurrentLanguageCode()]?.template_starting_point ?:
        baseLocale.template_starting_point

  public val about_part1: String
    get() = locales[getCurrentLanguageCode()]?.about_part1 ?: baseLocale.about_part1

  public val about_part2: String
    get() = locales[getCurrentLanguageCode()]?.about_part2 ?: baseLocale.about_part2

  public val about: String
    get() = locales[getCurrentLanguageCode()]?.about ?: baseLocale.about

  public val cta: String
    get() = locales[getCurrentLanguageCode()]?.cta ?: baseLocale.cta
}
