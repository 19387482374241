package games.bigoud.eucoalitionplanningfront.components.layouts

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import games.bigoud.eucoalitionplanningfront.config.authService
import games.bigoud.eucoalitionplanningfront.models.AuthenticationState
import games.bigoud.eucoalitionplanningfront.models.UserRole.UNKNOWN
import games.bigoud.eucoalitionplanningfront.utils.Constants.DISCORD_LOGIN_URL
import kotlinx.browser.window
import kotlinx.coroutines.launch

@Composable
fun AuthLayout(
    isLoginRequired: Boolean,
    redirectPath: String? = null,
    content: @Composable (AuthenticationState) -> Unit
) {

    var path = ""

    val ctx = rememberPageContext()

    redirectPath?.let {
        path = "&state=$it"
    }

    var authState by remember { mutableStateOf<AuthenticationState>(AuthenticationState.Unknown) }

    window.localStorage.getItem("token")?.let { token ->

        val scope = rememberCoroutineScope()

        //val authApi = ktorfit.create<AuthApi>()

        scope.launch {
            try {
                var userResponse = authService.login(token)
                window.localStorage.setItem("token", userResponse.token)
                authState = if (userResponse.userRole != UNKNOWN) {
                    AuthenticationState.Authenticated(userResponse)
                } else {
                    AuthenticationState.Unauthorized(userResponse)
                }

                //            var userResponse = Json.decodeFromString<DiscordUser>(
//                client.submitForm(
//                    url = "$BACKEND_URL/user",
//                    formParameters = parameters {
//                        append("token", token)
//                    }
//                ).bodyAsText()
//            )
            } catch (e: Exception) {
                println(e.cause)
                if (isLoginRequired) {
                    ctx.router.navigateTo(
                        DISCORD_LOGIN_URL + path,
                        openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                    )
                }
            }

        }

        content(authState)

    } ?: run {
        if (isLoginRequired) {
            ctx.router.navigateTo(DISCORD_LOGIN_URL + path, openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE)
        } else {
            content(authState)
        }
    }
}
