package games.bigoud.eucoalitionplanningfront.models

import kotlinx.serialization.Serializable

@Serializable
enum class TaskStatus {
    PENDING,
    CONFIRMED,
    BACKUP,
}
