package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val connection: String = "Login with Discord"

  override val template_starting_point: String = "Use this template as your starting point for"

  override val about_part1: String = "You can read the"

  override val about_part2: String = "page for more information."

  override val about: String = "About"

  override val cta: String = "This could be your CTA"
}
