package games.bigoud.eucoalitionplanningfront.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class SetupScheduleWeek(
    val year: Int,
    val weekNumber: Int,
    val tasks: List<Task>,
    val usernames: MutableMap<String, String> = mutableMapOf<String, String>()
) {
    companion object {
        fun dummy(): SetupScheduleWeek {
            val dummyTask = Task.dummy()
            return SetupScheduleWeek(
                0,
                0,
                listOf(),
                mutableMapOf()
            )
        }
    }

    fun updateTaskStatus(date: LocalDate, userId: String, taskType: TaskType, taskStatus: TaskStatus): SetupScheduleWeek {
        return copy(tasks = tasks.map { task ->
            if (task.day == date && task.userId == userId && task.taskType == taskType) {
                task.copy(taskStatus = taskStatus)
            } else {
                task
            }
        })
    }

    fun debug() {

        fun printTask(task: Task) {
            println(task.taskStatus)
        }

        val monday = LocalDate(2024, 11, 18)
        val taskBigoud = tasks.filter { task ->
            task.day == monday
        }.filter { task ->
            task.taskType == TaskType.CONF_DISTRIB
        }.first { task ->
            task.userId == "208671836605644800"
        }

        val taskNef = tasks.filter { task ->
            task.day == monday
        }.filter { task ->
            task.taskType == TaskType.CONF_DISTRIB
        }.first { task ->
            task.userId == "768936261590843412"
        }

        println("Task Bigoud")
        printTask(taskBigoud)
        println()
        println("Task Nef")
        printTask(taskNef)
        println()
    }
}
