package games.bigoud.eucoalitionplanningfront.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class Week(
    val year: Int,
    val weekNumber: Int,
    val mondayTasks: TaskList,
    val tuesdayTasks: TaskList,
    val wednesdayTasks: TaskList,
    val thursdayTasks: TaskList,
    val fridayTasks: TaskList,
    val saturdayTasks: TaskList,
    val sundayTasks: TaskList,
) {
    companion object {
        fun dummy(): Week {
            val dummyTask = Task.dummy()
            val dummyTaskList = TaskList(
                dummyTask,
                dummyTask,
                dummyTask,
                dummyTask,
            )
            return Week(
                0,
                0,
                dummyTaskList,
                dummyTaskList,
                dummyTaskList,
                dummyTaskList,
                dummyTaskList,
                dummyTaskList,
                dummyTaskList,
            )
        }
    }

    fun selectTask(dayIndex: Int, taskType: TaskType): Task {
        return when(dayIndex) {
            1 -> mondayTasks.selectTask(taskType)
            2 -> tuesdayTasks.selectTask(taskType)
            3 -> wednesdayTasks.selectTask(taskType)
            4 -> thursdayTasks.selectTask(taskType)
            5 -> fridayTasks.selectTask(taskType)
            6 -> saturdayTasks.selectTask(taskType)
            7 -> sundayTasks.selectTask(taskType)
            else -> {
                Task("", "", LocalDate(1,1,1), TaskAvailability.UNAVAILABLE, TaskType.CONF_DISTRIB, TaskStatus.PENDING)
            }
        }
    }

    fun updateTaskAvailability(dayIndex: Int, taskType: TaskType, taskAvailability: TaskAvailability): Week {
        return when(dayIndex) {
            1 -> copy(mondayTasks = mondayTasks.updateTaskAvailability(taskType, taskAvailability))
            2 -> copy(tuesdayTasks = tuesdayTasks.updateTaskAvailability(taskType, taskAvailability))
            3 -> copy(wednesdayTasks = wednesdayTasks.updateTaskAvailability(taskType, taskAvailability))
            4 -> copy(thursdayTasks = thursdayTasks.updateTaskAvailability(taskType, taskAvailability))
            5 -> copy(fridayTasks = fridayTasks.updateTaskAvailability(taskType, taskAvailability))
            6 -> copy(saturdayTasks = saturdayTasks.updateTaskAvailability(taskType, taskAvailability))
            7 -> copy(sundayTasks = sundayTasks.updateTaskAvailability(taskType, taskAvailability))
            else -> {
                copy()
            }
        }
    }
}
