package games.bigoud.eucoalitionplanningfront.components.sections.home

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaDiscord
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import games.bigoud.eucoalitionplanningfront.utils.Constants.DISCORD_LOGIN_URL
import org.jetbrains.compose.web.css.px

@Composable
fun UnauthenticatedHomeSection() {

    val scope = rememberCoroutineScope()

    Column(
        modifier = Modifier
            .fillMaxHeight(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        FaDiscord(
            modifier = Modifier
                .margin(bottom = 10.px),
            size = IconSize.X8
        )
        Link(
            DISCORD_LOGIN_URL,
            Res.string.connection,
            variant = UncoloredLinkVariant,
            openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
        )
    }
}
