package games.bigoud.eucoalitionplanningfront.models

import kotlinx.serialization.Serializable

@Serializable
data class DiscordUser(
    val discordId: String,
    val username: String,
    val globalName: String,
    val avatar: String?,
    val discriminator: String,
    val email: String?,
    val verified: Boolean?,
    val token: String,
    val userRole: UserRole,
) {
    fun avatarUrl() = "https://cdn.discordapp.com/avatars/$discordId/$avatar.webp"
}
