// Generated by Ktorfit
@file:Suppress("warnings")

package games.bigoud.eucoalitionplanningfront.services

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.ClassProvider
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.converter.TypeData
import games.bigoud.eucoalitionplanningfront.models.Planning
import games.bigoud.eucoalitionplanningfront.models.SetupScheduleWeek
import games.bigoud.eucoalitionplanningfront.models.Week
import games.bigoud.eucoalitionplanningfront.modelsNetwork.UpdateScheduleRequest
import games.bigoud.eucoalitionplanningfront.modelsNetwork.UpdateWeekRequest
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.forms.FormDataContent
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.Parameters
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.Boolean
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress

@OptIn(InternalKtorfitApi::class)
public class _PlanningApiImpl(
  private val _ktorfit: Ktorfit,
) : PlanningApi {
  private val _helper: KtorfitConverterHelper = KtorfitConverterHelper(_ktorfit)

  override suspend fun getPlanning(
    token: String,
    year: String,
    month: String,
    day: String,
  ): Planning {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_ktorfit.baseUrl + "planning")
        }
        headers{
        append("Content-Type", "application/x-www-form-urlencoded")
        }
        val __formParameters = Parameters.build {
        token?.let{ append("token", "${it}") }
        year?.let{ append("year", "${it}") }
        month?.let{ append("month", "${it}") }
        day?.let{ append("day", "${it}") }
        }
        setBody(FormDataContent(__formParameters))
         
        }
    val _typeData = TypeData.createTypeData(
    typeInfo = typeInfo<Planning>(),
    )
    return _helper.suspendRequest(_typeData,_ext)!!
  }

  override suspend fun getAvailabilitySchedule(
    token: String,
    week: String,
    year: String,
    day: String,
  ): Week {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_ktorfit.baseUrl + "planning_user")
        }
        headers{
        append("Content-Type", "application/x-www-form-urlencoded")
        }
        val __formParameters = Parameters.build {
        token?.let{ append("token", "${it}") }
        week?.let{ append("year", "${it}") }
        year?.let{ append("month", "${it}") }
        day?.let{ append("day", "${it}") }
        }
        setBody(FormDataContent(__formParameters))
         
        }
    val _typeData = TypeData.createTypeData(
    typeInfo = typeInfo<Week>(),
    )
    return _helper.suspendRequest(_typeData,_ext)!!
  }

  override suspend fun updateAvailabilitySchedule(weekPlanning: UpdateWeekRequest,
      contentType: String): Boolean {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_ktorfit.baseUrl + "update_planning_user")
        }
        setBody(weekPlanning)
        headers{
        append("Content-Type", contentType)
        } 
        }
    val _typeData = TypeData.createTypeData(
    typeInfo = typeInfo<Boolean>(),
    )
    return _helper.suspendRequest(_typeData,_ext)!!
  }

  override suspend fun getSetupScheduleTest(
    token: String,
    week: String,
    year: String,
    day: String,
  ): SetupScheduleWeek {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_ktorfit.baseUrl + "setup_planning")
        }
        headers{
        append("Content-Type", "application/x-www-form-urlencoded")
        }
        val __formParameters = Parameters.build {
        token?.let{ append("token", "${it}") }
        week?.let{ append("year", "${it}") }
        year?.let{ append("month", "${it}") }
        day?.let{ append("day", "${it}") }
        }
        setBody(FormDataContent(__formParameters))
         
        }
    val _typeData = TypeData.createTypeData(
    typeInfo = typeInfo<SetupScheduleWeek>(),
    )
    return _helper.suspendRequest(_typeData,_ext)!!
  }

  override suspend fun updateSetupSchedule(planning: UpdateScheduleRequest, contentType: String):
      Boolean {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_ktorfit.baseUrl + "update_setup_planning")
        }
        setBody(planning)
        headers{
        append("Content-Type", contentType)
        } 
        }
    val _typeData = TypeData.createTypeData(
    typeInfo = typeInfo<Boolean>(),
    )
    return _helper.suspendRequest(_typeData,_ext)!!
  }
}

public class _PlanningApiProvider : ClassProvider<PlanningApi> {
  override fun create(_ktorfit: Ktorfit): PlanningApi = _PlanningApiImpl(_ktorfit)
}

public fun Ktorfit.createPlanningApi(): PlanningApi = _PlanningApiImpl(this)
