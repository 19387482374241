package games.bigoud.eucoalitionplanningfront.components.sections.home

import androidx.compose.runtime.*
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.silk.components.text.SpanText
import games.bigoud.eucoalitionplanningfront.components.widgets.PlanningTab
import games.bigoud.eucoalitionplanningfront.components.widgets.dateSelector
import games.bigoud.eucoalitionplanningfront.config.planningService
import games.bigoud.eucoalitionplanningfront.models.DiscordUser
import games.bigoud.eucoalitionplanningfront.models.Planning
import games.bigoud.eucoalitionplanningfront.models.TaskStatus
import games.bigoud.eucoalitionplanningfront.utils.getWeekNumber
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.todayIn
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun AuthenticatedHomeSection(user: DiscordUser) {

    val scope = rememberCoroutineScope()

    var planning by remember { mutableStateOf(Planning(mutableListOf())) }

    var date = Clock.System.todayIn(TimeZone.of("Europe/Paris"))
    var numberOfDayInWeek = date.dayOfWeek.ordinal
    var mondayDate by remember { mutableStateOf(date.minus(numberOfDayInWeek, DateTimeUnit.DAY)) }

    scope.launch {
        val token = window.localStorage.getItem("token")
        planning = planningService.getPlanning(
            token ?: "",
            mondayDate.year.toString(),
            mondayDate.monthNumber.toString(),
            mondayDate.dayOfMonth.toString(),
        )
    }

    dateSelector(mondayDate) { newDate ->
        mondayDate = newDate
    }

    PlanningTab(user, mondayDate) { date, taskType ->
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
        ) {
            val confirmedTask = planning.tasks.firstOrNull { task ->
                task.day == date && task.taskType == taskType && task.taskStatus == TaskStatus.CONFIRMED
            }
            val confirmedUser = planning.usernames[confirmedTask?.userId] ?: "Aucun responsable"
            SpanText(
                text = confirmedUser,
                modifier = Modifier
                    .color(Color.black)
            )
            val backupTask = planning.tasks.firstOrNull { task ->
                task.day == date && task.taskType == taskType && task.taskStatus == TaskStatus.BACKUP
            }
            val backupUser = planning.usernames[backupTask?.userId] ?: "Aucun backup"
            SpanText(
                text = backupUser,
                modifier = Modifier
                    .color(Color.black)
            )
        }
    }
}
