package games.bigoud.eucoalitionplanningfront.models

import kotlinx.serialization.Serializable

@Serializable
data class TaskList(
    val confDistribTask: Task,
    val groupTask: Task,
    val briefTask: Task,
    val leadTask: Task,
) {
    fun selectTask(taskType: TaskType): Task {
        return when(taskType) {
            TaskType.CONF_DISTRIB -> confDistribTask
            TaskType.GROUPS -> groupTask
            TaskType.BRIEF -> briefTask
            TaskType.LEAD -> leadTask
        }
    }

    fun updateTaskAvailability(taskType: TaskType, taskAvailability: TaskAvailability): TaskList {
        return when(taskType) {
            TaskType.CONF_DISTRIB -> copy(confDistribTask = confDistribTask.copy(taskAvailability = taskAvailability))
            TaskType.GROUPS -> copy(groupTask = groupTask.copy(taskAvailability = taskAvailability))
            TaskType.BRIEF -> copy(briefTask = briefTask.copy(taskAvailability = taskAvailability))
            TaskType.LEAD -> copy(leadTask = leadTask.copy(taskAvailability = taskAvailability))
        }
    }
}
