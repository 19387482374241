package games.bigoud.eucoalitionplanningfront.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Task(
    @Contextual
    @SerialName("_id")
    val id: String,
    val userId: String,
    val day: LocalDate,
    val taskAvailability: TaskAvailability,
    val taskType: TaskType,
    val taskStatus: TaskStatus,
) {
    companion object {
        fun dummy(): Task {
            return Task("", "", LocalDate(1,1,1), TaskAvailability.UNAVAILABLE, TaskType.CONF_DISTRIB, TaskStatus.PENDING)
        }
    }
}
