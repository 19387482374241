package games.bigoud.eucoalitionplanningfront.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import games.bigoud.eucoalitionplanningfront.components.layouts.AuthLayout
import games.bigoud.eucoalitionplanningfront.components.layouts.PageLayout
import games.bigoud.eucoalitionplanningfront.components.sections.home.AuthenticatedHomeSection
import games.bigoud.eucoalitionplanningfront.components.sections.home.UnauthenticatedHomeSection
import games.bigoud.eucoalitionplanningfront.components.sections.home.UnauthorizedHomeSection
import games.bigoud.eucoalitionplanningfront.models.AuthenticationState
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HomePage() {
    AuthLayout(false) {
        PageLayout("Admin Planning", it) {
            when(it) {
                AuthenticationState.Unknown,
                AuthenticationState.Unauthenticated -> UnauthenticatedHomeSection()
                AuthenticationState.Authenticating -> Text("Hello, en cours d'authent")
                is AuthenticationState.Authenticated -> AuthenticatedHomeSection(it.user)
                is AuthenticationState.Unauthorized -> UnauthorizedHomeSection(it.user)
            }
        }
    }
}
