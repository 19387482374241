// Generated by Ktorfit
@file:Suppress("warnings")

package games.bigoud.eucoalitionplanningfront.services

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.ClassProvider
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.converter.TypeData
import games.bigoud.eucoalitionplanningfront.models.DiscordUser
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.forms.FormDataContent
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.Parameters
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress

@OptIn(InternalKtorfitApi::class)
public class _AuthApiImpl(
  private val _ktorfit: Ktorfit,
) : AuthApi {
  private val _helper: KtorfitConverterHelper = KtorfitConverterHelper(_ktorfit)

  override suspend fun login(token: String): DiscordUser {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_ktorfit.baseUrl + "user")
        }
        headers{
        append("Content-Type", "application/x-www-form-urlencoded")
        }
        val __formParameters = Parameters.build {
        token?.let{ append("token", "${it}") }
        }
        setBody(FormDataContent(__formParameters))
         
        }
    val _typeData = TypeData.createTypeData(
    typeInfo = typeInfo<DiscordUser>(),
    )
    return _helper.suspendRequest(_typeData,_ext)!!
  }
}

public class _AuthApiProvider : ClassProvider<AuthApi> {
  override fun create(_ktorfit: Ktorfit): AuthApi = _AuthApiImpl(_ktorfit)
}

public fun Ktorfit.createAuthApi(): AuthApi = _AuthApiImpl(this)
