package games.bigoud.eucoalitionplanningfront.components.sections.availabilitySchedule

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.toAttrs
import games.bigoud.eucoalitionplanningfront.components.widgets.PlanningTab
import games.bigoud.eucoalitionplanningfront.components.widgets.dateSelector
import games.bigoud.eucoalitionplanningfront.config.planningService
import games.bigoud.eucoalitionplanningfront.models.DiscordUser
import games.bigoud.eucoalitionplanningfront.models.TaskAvailability
import games.bigoud.eucoalitionplanningfront.models.Week
import games.bigoud.eucoalitionplanningfront.modelsNetwork.UpdateWeekRequest
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.isoDayNumber
import kotlinx.datetime.minus
import kotlinx.datetime.todayIn
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

@Composable
fun AuthenticatedAvailabilityScheduleSection(user: DiscordUser) {

    val scope = rememberCoroutineScope()

    var planning by remember { mutableStateOf(Week.dummy())}

    var date = Clock.System.todayIn(TimeZone.of("Europe/Paris"))
    var numberOfDayInWeek = date.dayOfWeek.ordinal
    var mondayDate by remember { mutableStateOf(date.minus(numberOfDayInWeek, DateTimeUnit.DAY))}

    scope.launch {
        val token = window.localStorage.getItem("token")
        planning = planningService.getAvailabilitySchedule(
            token?: "",
            mondayDate.year.toString(),
            mondayDate.monthNumber.toString(),
            mondayDate.dayOfMonth.toString(),
        )
    }

    dateSelector(mondayDate) { newDate ->
        mondayDate = newDate
    }

    PlanningTab(user, mondayDate) { date, taskType ->
        Select(
            {
                onChange {
                    planning = planning.updateTaskAvailability(date.dayOfWeek.isoDayNumber, taskType, TaskAvailability.valueOf(it.value ?: TaskAvailability.UNAVAILABLE.name))
                }
            }
        ) {
            TaskAvailability.entries.forEach {
                Option(
                    value = it.name,
                    attrs = Modifier
                        .attrsModifier {
                            if (planning.selectTask(date.dayOfWeek.isoDayNumber, taskType).taskAvailability.name == it.name) {
                                attr("selected", planning.selectTask(date.dayOfWeek.isoDayNumber, taskType).taskAvailability.name)
                            }
                        }
                        .toAttrs()
                ) {
                    Text(it.description)
                }
            }
        }
    }
    Button(
        attrs = Modifier
            .onClick {
                scope.launch {
                    val token = window.localStorage.getItem("token")
                    val response = planningService.updateAvailabilitySchedule(UpdateWeekRequest(token?: "", planning))
                    if (response) {

                    }
                }
            }
            .toAttrs()
    ) {
        Text("Click me")
    }
}
