package games.bigoud.eucoalitionplanningfront.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import games.bigoud.eucoalitionplanningfront.components.layouts.AuthLayout
import games.bigoud.eucoalitionplanningfront.components.layouts.PageLayout
import games.bigoud.eucoalitionplanningfront.components.sections.setupSchedule.AuthenticatedSetupScheduleSection
import games.bigoud.eucoalitionplanningfront.models.AuthenticationState

@Page
@Composable
fun SetupSchedule() {
    AuthLayout(true, "setup-schedule") {
        PageLayout("Setup Schedule", it) {
            val ctx = rememberPageContext()
            when(it) {
                AuthenticationState.Unknown,
                AuthenticationState.Unauthenticated,
                AuthenticationState.Authenticating -> {}
                is AuthenticationState.Authenticated -> AuthenticatedSetupScheduleSection(it.user)
                is AuthenticationState.Unauthorized -> {
                    ctx.router.navigateTo("/")
                }
            }
        }
    }
}
