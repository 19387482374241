package games.bigoud.eucoalitionplanningfront.utils

import kotlinx.datetime.LocalDate
import kotlinx.datetime.Month
import kotlin.js.Date

fun LocalDate.getWeekNumber(): Int {
    return kotlinx.datetime.internal.JSJoda.LocalDate.of(year, monthNumber, dayOfMonth).isoWeekOfWeekyear()
}

fun LocalDate.localized(): String {
    val jsDate = Date(this.year, this.monthNumber-1, this.dayOfMonth)
    val locale = js("navigator.language || navigator.userLanguage")
    val formatter = js("new Intl.DateTimeFormat(locale, { weekday: 'long', day: 'numeric', month: 'long' })")
    return (formatter.format(jsDate) as String).replaceFirstChar(Char::uppercase)
}
