package games.bigoud.eucoalitionplanningfront.models

import kotlinx.serialization.Serializable

@Serializable
enum class TaskAvailability(val description: String) {
    WISH("Souhait"),
    AVAILABLE("Dispo"),
    MAYBE("Maybe si besoin"),
    UNAVAILABLE("Pas dispo"),
}
